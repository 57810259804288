import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n.js'
//import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path:'/',
  //   name:'test',
  //   meta:{
  //     title:'AOSEED 爱创星球'
  //   },
  //   component: HomeView
  // },
  {
    path:'/',
    name:'home',
    meta:{
      title:'router_home'
    },
    component: () => import('../views/HomeView.vue')
  },
  {
    path:'/downloads',
    name:'down',
    meta:{
      title:'router_home'
    },
    component: () => import('../views/DownloadView.vue')
  },
  {
    path:'/downloads/X-MAKER',
    name:'downmaker',
    meta:{
      title:'router_home'
    },
    component: () => import('../views/DownloadView.vue')
  },
  {
    path:'/downloads/X-KIT',
    name:'downkit',
    meta:{
      title:'router_home'
    },
    component: () => import('../views/DownloadView.vue')
  },
  {
    path:'/downloads/X-PRINT',
    name:'downorint',
    meta:{
      title:'router_home'
    },
    component: () => import('../views/DownloadView.vue')
  },
  
  {
    path:'/products/X-MAKER',
    name:'xmaker',
    meta:{
      title:'router_home'
    },
    component: () => import('../views/XmakerView.vue')
  },
  {
    path: '/products/X-KIT',
    name: 'xkit',
    meta:{
      title:''
    },
    component: () => import('../views/XkitView.vue')
  },
  {
    path: '/support/X-KIT',
    name: 'xkitdate',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/XkitdateView.vue')
  },
  {
    path: '/support/X-MAKER',
    name: 'xmakerdate',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/XmakerdateView.vue')
  },
  // {
  //   path: '/support',
  //   name: 'support',
  //   meta: {
  //     title:'router_home'
  //   },
  //   component: () => import('../views/SupportView.vue'),
  // },
  // {
  //   path: '/store',
  //   name: 'store',
  //   meta: {
  //     title:'router_home'
  //   },
  //   component: () => import('../views/ShopView.vue')
  // },
  {
    path: '/term',
    name: 'term',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/termView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/privacyView.vue')
  },
  {
    path: '/aoseed/Mine/XMAKER/Privacy',
    name: 'x-privacy',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/aoseed/mine/Xmaker/PrivacyView.vue')
  },
  {
    path: '/aoseed/Mine/XMAKER/XmakerPrivacyChild',
    name: 'x-privacy',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/aoseed/mine/Xmaker/XmakerPrivacyChild.vue')
  },
  {
    path: '/aoseed/Mine/XMAKER/XmakerHDPrivacy',
    name: 'x-HDprivacy',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/aoseed/mine/Xmaker/PrivacyHDView.vue')
  },
  {
    path: '/aoseed/Mine/XMAKER/Terms_EN',
    name: 'entermsx',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/aoseed/mine/Xmaker/TermsENView.vue')
  },
  {
    path: '/aoseed/Mine/XMAKER/Privacy_EN',
    name: 'enprivacys',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/aoseed/mine/Xmaker/PrivacyENView.vue')
  },
  //X-MAKER隐私条例
  {
    path: '/aoseed/Mine/X-MAKER/Terms_EN',
    name: 'entermsX',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/aoseed/mine/X-MAKER/TermsENView.vue')
  },
  {
    path: '/aoseed/Mine/X-MAKER/Privacy_EN',
    name: 'enprivacy',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/aoseed/mine/X-MAKER/PrivacyENView.vue')
  },
  {
    path: '/aoseed/Mine/XMAKER/Terms',
    name: 'x-terms',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/aoseed/mine/Xmaker/TermsView.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutus',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/AboutusView.vue')
  },
  {
    path: '/contactUs',
    name: 'contactus',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/ContactusView.vue')
  },
  {
    path: '/personcenter',
    name: 'personcenter',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/PersonCenterView.vue')
  },
  {
    path: '/personcenter1',
    name: 'personcenter1',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/PersonCenterView.vue')
  },
  {
    path: '/workdate',
    name: 'workdate',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/WorkdateView.vue')
  },
  {
    path: '/Matchdate',
    name: 'MatchdateView',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/MatchdateView.vue')
  },
  {
    path: '/workpublish',
    name: 'workpublish',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/WorkPublishView.vue')
  },
  {
    path: '/Login/ChangePassword/:email',
    name: 'changepassword',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/ChangePasswordView.vue')
  },
  {
    path:'/backsuccess',
    name: 'backsuccess',
    meta: {
      title:'router_home'
    },
    component:() => import('../views/BacksuccessView.vue')
  },
  {
    path:'/backfail',
    name: 'backfail',meta: {
      title:'router_home'
    },
    component:() => import('../views/BacksuccessView.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/login/deleteAccount',
    name: 'deleteAccount',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/deleteAccountView.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/findaccount',
    name: 'findaccount',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/FindAccountView.vue')
  },
  {
    path: '/reseller',
    name: 'reseller',
    meta: {
      title:'router_home'
    },
    component: () => import('../views/ResellerView.vue')
  },
  {
    path:'/Activate',
    name:'Activate',
    meta:{
      title:'router_home'
    },
    component:()=> import ('../views/ActivateView.vue')
  },
  {
    path:'/affiliate-program',
    name:'about',
    meta:{
      title:'router_home'
    },
    component:()=> import ('../views/aboutView.vue')
  },
  {
    path:'/Competitions',
    name:'Competitions',
    meta:{
      title:'router_home'
    },
    component:()=> import ('../views/CompetitionsView.vue')
  },
  {
  path:'/Mine',
  name:'mine',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/MineView.vue')
},
{
  path:'/Upload',
  name:'upload',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/uploadView.vue')
},
{
  path:'/FastGPT',
  name:'FastGPT',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/aoseed/mine/SearchView.vue')
},
{
  path:'/Things',
  name:'things',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/ThingsView.vue')
},
{
  path:'/Explore',
  name:'explore',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/ExploreView.vue')
},
{
  path:'/ExploreDetail/:id',
  name:'exploreDetail',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/ExploreDetailView.vue')
},
{
  path:'/CourseDetail/:id',
  name:'courseDetail',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/courseDetailView.vue')
},
{
  path:'/products/Creationkit',
  name:'Creationkit',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/CreationkitView.vue')
},
//大赛模块
{
  path:'/Contest3D',
  name:'Contest3D',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/Contest/contest3D.vue')
},
{
  path:'/Contest',
  name:'Contest',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/Contest/index.vue')
},
{
  path:'/ContestUpload',
  name:'ContestUpload',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/Contest/upload.vue')
},
{
  path:'/Guide/xmaker-joy/en',
  name:'GuideEn',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views//Guide/xmaker-joy/en.vue')
},
{
  path:'/Guide/xmaker-joy/cn',
  name:'GuideCh',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/Guide/xmaker-joy/cn.vue')
},
{
  path:'/Guide/xmaker/cn',
  name:'xmakerCh',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/Guide/xmaker/cn.vue')
},
{
  path:'/ToyLibrary',
  name:'toyLibrary',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/ToyLibraryView.vue')
},
// {
//   path:'/newaoseed',
//   name:'newaoseed',
//   meta:{
//     title:'router_home'
//   },
//   component:()=> import ('../views/newAoseed.vue')
// },
{
  path:'/Xmaker-App',
  name:'Xmaker-down',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/XmakerAppdown.vue')
},
{
  path:'/products/XMAKER-JOY',
  name:'XMAKER-JOY',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/XmakerJoy.vue')
},
// {
//   path:'/video',
//   name:'video',
//   meta:{
//     title:'router_home'
//   },
//   component:()=> import ('../views/printer/video.vue')
// },
{
  path:'/XMAKER-chat',
  name:'helper',
  meta:{
    title:'router_home'
  },
  component:()=> import ('../views/AI/helper.vue')
},
]


const router = new VueRouter({
  mode: 'history',
  // base: '',
  routes,
  scrollBehavior(to,from,savedPosition) {
    return {x:0,y:0,}
  }
})

router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title = i18n.t(to.meta.title)
  }
  if(to.matched.length === 0 ){
    next("/")
  }
  next()
})

export default router

